import React, { FC } from 'react';
import styled from 'styled-components';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import { DynamicImage, PrimaryButton, Text } from 'components';

interface ResultsHabitsProps {
  title?: string;
  imgMob?: string;
  img?: string;
  nowTitle?: string;
  nowList?: string[];
  afterTitle?: string;
  afterList?: string[];
}

const ResultsHabits: FC<ResultsHabitsProps> = ({
  title,
  imgMob,
  img,
  nowTitle,
  nowList,
  afterList,
  afterTitle,
}) => {
  const { isTablet } = useQuery();

  return (
    <>
      <Title>{title}</Title>
      <ChartContainer>
        <ImgContainer>
          <StyledImg src={isTablet ? imgMob! : img!} alt="" />
        </ImgContainer>
        <SideContainer>
          <LeftSide>
            <LeftTitle dangerouslySetInnerHTML={{ __html: nowTitle! }} />
            <ListBox>
              {nowList?.map((item: string, index: number) => (
                <ListItem
                  key={index}
                  dangerouslySetInnerHTML={{ __html: item }}
                />
              ))}
            </ListBox>
          </LeftSide>

          <RightSide>
            <RightTitle dangerouslySetInnerHTML={{ __html: afterTitle! }} />
            <ListBox>
              {afterList?.map((item: string, index: number) => (
                <ListItem
                  key={index}
                  dangerouslySetInnerHTML={{ __html: item }}
                />
              ))}
            </ListBox>
          </RightSide>
        </SideContainer>
      </ChartContainer>
    </>
  );
};

export default ResultsHabits;

const Title = styled(Text)`
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;

  @media ${tablet} {
    font-size: 1.25rem;
    line-height: 1.375rem;
  }
`;

const StyledImg = styled(DynamicImage)`
  height: 100%;
  width: 100%;
  max-width: 29.25rem;
  max-height: 8.625rem;

  @media ${tablet} {
    max-width: 19.375rem;
    max-height: 7.25rem;
  }
`;

const ImgContainer = styled.section`
  width: 100%;
  max-height: 8.125rem;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 2.41rem;

  @media ${tablet} {
    margin-bottom: 2.39rem;
  }
`;

const ChartContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1.5rem 0;
  padding: 1rem 0.5rem;
  border-radius: 0.75rem;

  background: rgb(227, 136, 128);
  background: linear-gradient(
    90deg,
    rgba(227, 136, 128, 1) 50%,
    rgba(102, 211, 175, 1) 50%
  );
`;

const SideContainer = styled.div`
  display: flex;
  width: 100%;
`;

const LeftSide = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  border: 1px solid #e38880;
  border-right: 0;
  background: #e38880;
`;

const RightSide = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  border: 1px solid rgb(102, 211, 175);
  border-left: none;
  background: #66d3af;
  margin-left: 1rem;
`;

const LeftTitle = styled.p`
  color: #000;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 800;
  line-height: 1.375rem;
  @media ${tablet} {
    font-size: 1rem;
    font-style: normal;
    font-weight: 800;
    line-height: 1.375rem;
  }

  span {
    @media ${mobile} {
      display: block;
    }
  }
`;

const RightTitle = styled(LeftTitle)``;

const ListBox = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
`;

const ListItem = styled(Text)`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  @media ${mobile} {
    span {
      display: block;
    }
  }
`;
