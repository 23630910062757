import React, { FC } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';
//@ts-ignore
import Check from 'assets/icons/results/checkmark.svg';
//@ts-ignore
import BookGif from 'assets/images/lastingChange/Book_Gif.gif';
import { Text } from 'components';

interface IListItem {
  title?: string;
  text?: string;
}

interface ResultsInfoProps {
  title?: string;
  subtitle?: string;
  list?: IListItem[];
}

const ResultsAbout: FC<ResultsInfoProps> = ({ title, subtitle, list }) => (
  <>
    <Title dangerouslySetInnerHTML={{ __html: title! }} />
    <Subtitle>{subtitle}</Subtitle>
    <ImgContainer>
      <StyledImg src={BookGif} alt="bookImage" style={{ maxWidth: '100%' }} />
    </ImgContainer>
    <List>
      {list?.map((item, index) => (
        <ListItem key={index}>
          <Svg>
            <Check />
          </Svg>
          {index === list.length - 1 ? null : <Line />}
          <ListInner>
            <ListTitle dangerouslySetInnerHTML={{ __html: item.title! }} />
            <ListText dangerouslySetInnerHTML={{ __html: item.text! }} />
          </ListInner>
        </ListItem>
      ))}
    </List>
  </>
);

export default ResultsAbout;

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.5rem 0;
`;

const StyledImg = styled.img`
  width: 21.53425rem;
  height: 18.30413rem;
  object-fit: cover;

  @media ${tablet} {
    width: 19.38775rem;
    height: 14.47956rem;
  }
`;

const Title = styled(Text)`
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;

  @media ${tablet} {
    font-size: 1.25rem;
    line-height: 1.375rem;
  }
`;

const Subtitle = styled(Text)`
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  padding-top: 0.5rem;
`;
const List = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  width: 100%;
  margin-bottom: 1.5rem;
`;

const ListItem = styled.section`
  position: relative;
  display: flex;
`;
const ListInner = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  margin-left: 1rem;
`;

const ListTitle = styled(Text)`
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.375rem;
  span {
    color: #00b57a;
  }
`;

const ListText = styled(Text)`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
`;

const Line = styled.div`
  height: 108%;
  width: 1px;
  background-color: #00b57a;
  position: absolute;
  top: 16px;
  left: 9px;
`;

const Svg = styled.section`
  width: 1.25rem;
  height: 1.32531rem;
  position: relative;
  z-index: 3;
  svg {
    width: 1.25rem;
    height: 1.32531rem;
  }
`;
