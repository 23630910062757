import React, { FC } from 'react';
import styled from 'styled-components';
import { mobile, smMobile, tablet, useQuery } from 'styles/breakpoints';
import { DynamicImage, Text } from 'components';
import { getDateLanguageString } from 'utils/dates';

interface ResultsChartProps {
  title?: string;
  subtitle?: string;
  imgMob?: string;
  img?: string;
  dateFormat?: string;
}

const ResultsChart: FC<ResultsChartProps> = ({
  title,
  subtitle,
  imgMob,
  img,
  dateFormat = 'en-US', // Default to 'en-US' if not provided
}) => {
  const { isTablet, isMobile } = useQuery();

  const currentDate = new Date();
  const currentMonth = currentDate.toLocaleString(dateFormat, {
    month: 'long',
  });

  const nextMonth = new Date();
  nextMonth.setMonth(currentDate.getMonth() + 1);

  const thirdMonth = new Date();
  thirdMonth.setMonth(currentDate.getMonth() + 2);

  return (
    <>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <ImageWrapper>
        <StyledImg src={isTablet ? imgMob! : img!} alt="" />
        <MonthsLine>
          <MonthLabel>{currentMonth}</MonthLabel>
          <MonthLabel>
            {nextMonth.toLocaleString(dateFormat, {
              month: 'long',
            })}
          </MonthLabel>
          <MonthLabel>
            {thirdMonth.toLocaleString(dateFormat, {
              month: 'long',
            })}
          </MonthLabel>
        </MonthsLine>
      </ImageWrapper>
    </>
  );
};
export default ResultsChart;

const Title = styled(Text)`
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;

  @media ${tablet} {
    font-size: 1.25rem;
    line-height: 1.375rem;
  }
`;

const Subtitle = styled(Text)`
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  padding-top: 0.5rem;
`;

const StyledImg = styled(DynamicImage)`
  width: 100%;
`;

const ImageWrapper = styled.div`
  margin: 1.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MonthsLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 2.6rem;
  width: 100%;

  @media ${mobile} {
    padding-left: 1.7rem;
  }
`;

const MonthLabel = styled(Text)`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;

  @media ${mobile} {
    font-size: 0.75rem;
    line-height: 1rem;
  }
`;
