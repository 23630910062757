import React, { FC } from 'react';
import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';
import { DynamicImage, PrimaryButton, Text } from 'components';

interface ResultsProgramProps {
  title?: string;
  subtitle?: string;
  imgDes: string;
  imgMob: string;
  chartTitle?: string;
  today?: string;
  soon?: string;
}

const ResultsProgram: FC<ResultsProgramProps> = ({
  title,
  subtitle,
  imgDes,
  imgMob,
  chartTitle,
  today,
  soon,
}) => {
  const { isTablet } = useQuery();

  return (
    <>
      <Title>{title}</Title>
      <Subtitle dangerouslySetInnerHTML={{ __html: subtitle! }} />
      <ChartBox>
        <ChartTitle>{chartTitle}</ChartTitle>
        <StyledImg src={isTablet ? imgMob : imgDes} alt="" />
        <List>
          <ListItem>{today}</ListItem>
          <ListItem>{soon}</ListItem>
        </List>
      </ChartBox>
    </>
  );
};

export default ResultsProgram;

const ChartTitle = styled(Text)`
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 1.375rem;
  text-transform: uppercase;
  padding: 0 0.5rem 1.5rem;
  @media ${tablet} {
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 0 0.5rem 1rem;
  }
`;

const List = styled.section`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0.31rem 1rem 0;
  @media ${tablet} {
    padding: 0.22rem 0.44rem 0;
  }
`;

const ListItem = styled.p`
  color: #8e909a;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem;
  @media ${tablet} {
    font-size: 0.75rem;
    line-height: 1rem;
  }
`;

const ChartBox = styled.section`
  display: flex;
  padding: 1rem 0.5rem;
  flex-direction: column;
  align-items: center;
  border-radius: 0.625rem;
  background: #f7f7f7;
  margin: 1.5rem auto;
  @media ${tablet} {
  }
`;

const Title = styled(Text)`
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;

  @media ${tablet} {
    font-size: 1.25rem;
    line-height: 1.375rem;
  }
`;

const Subtitle = styled(Text)`
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  padding-top: 0.5rem;
`;

const StyledImg = styled(DynamicImage)``;
