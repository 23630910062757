import React, { FC } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';
//@ts-ignore
import Check from 'assets/icons/circle-checkmark.svg';
import { DynamicImage, Text } from 'components';

interface ResultsInfoProps {
  title?: string;
  subtitle?: string;
  img?: string;
  infoList?: string[];
}

const ResultsInfo: FC<ResultsInfoProps> = ({
  title,
  subtitle,
  img,
  infoList,
}) => (
  <>
    <Title>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
    <StyledImg src={img!} alt="" />
    <List>
      {infoList?.map((item: string, index: number) => (
        <ListBox>
          <Svg>
            <Check />
          </Svg>
          <ListItem key={index} dangerouslySetInnerHTML={{ __html: item }} />
        </ListBox>
      ))}
    </List>
  </>
);

export default ResultsInfo;

const Title = styled(Text)`
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;

  @media ${tablet} {
    font-size: 1.25rem;
    line-height: 1.375rem;
  }
`;

const Subtitle = styled(Text)`
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  padding-top: 0.5rem;
`;

const StyledImg = styled(DynamicImage)`
  margin: 0.8rem auto 1.2rem;
  height: 18.10694rem;
  @media ${tablet} {
    height: 16.5625rem;
    margin: 0 auto 0.5rem;
  }
`;

const List = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
  margin-bottom: 1.5rem;
`;

const ListBox = styled.section`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
`;

const ListItem = styled(Text)`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
`;

const Svg = styled.section`
  width: 1.5rem;
  height: 1.5rem;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
