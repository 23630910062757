import React, { FC } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';
//@ts-ignore
import WomensHealth from 'assets/icons/womens-health.svg';
//@ts-ignore
import PsychologyToday from 'assets/icons/vouge.svg';
import { Text } from 'components';

interface ResultsFeaturedProps {
  title?: string;
  first?: string;
  second?: string;
}

const ResultsFeatured: FC<ResultsFeaturedProps> = ({
  title,
  first,
  second,
}) => (
  <>
    <Title dangerouslySetInnerHTML={{ __html: title! }} />
    <InnerCard>
      <StyledWomensHealth />
      <TextInfo dangerouslySetInnerHTML={{ __html: first! }} />
    </InnerCard>
    <InnerCard>
      <StyledPsychologyToday />
      <TextInfo dangerouslySetInnerHTML={{ __html: second! }} />
    </InnerCard>
  </>
);

export default ResultsFeatured;

const StyledWomensHealth = styled(WomensHealth)`
  width: 11.87019rem;
  height: 2.4375rem;
`;

const StyledPsychologyToday = styled(PsychologyToday)`
  width: 11.05444rem;
  height: 2.4375rem;
`;

const Title = styled(Text)`
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  margin-bottom: 1.5rem;
  @media ${tablet} {
    font-size: 1.25rem;
    line-height: 1.375rem;
  }
`;

const InnerCard = styled.section`
  display: flex;
  padding: 1.5rem 1rem;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  border-radius: 0.75rem;
  background: #fff;
  box-shadow: 0px 4.935px 16px 0px rgba(0, 0, 0, 0.08);
  margin-bottom: 1.5rem;
`;

const TextInfo = styled(Text)`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  text-align: center;
`;
