import React, { FC } from 'react';
import styled from 'styled-components';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
//@ts-ignore
import Chart from 'assets/icons/results-summary.svg';
import { DynamicImage, Text } from 'components';

import Arrow from 'assets/icons/results/arrow.svg';
import Horizontal from 'assets/icons/results/horizontal-divider.svg';
import Vertical from 'assets/icons/results/divider.svg';
import High from 'assets/icons/results/high.svg';
import Medium from 'assets/icons/results/medium.svg';
import Low from 'assets/icons/results/low.svg';

interface ResultsSummaryProps {
  title?: string;
  subtitle?: string;
  img: string;
  boxText?: string;
  now: string;
  goal: string;
  consistency: string;
  low: string;
  high: string;
  medium: string;
  energy: string;
  motivation: string;
  imgGoal: string;
  imgNow: string;
}

const ResultsSummary: FC<ResultsSummaryProps> = ({
  title,
  subtitle,
  imgNow,
  imgGoal,
  boxText,
  now,
  goal,
  consistency,
  low,
  high,
  medium,
  energy,
  motivation,
}) => {
  const { isTablet } = useQuery();

  return (
    <>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <TopSection>
        <TopInnerSection>
          <Now>
            <NowText>{now}</NowText>
          </Now>
          <Img src={imgNow} alt="" />
        </TopInnerSection>
        <Svg>
          <ArrowSvg />
        </Svg>
        <TopInnerSection>
          <After>
            <AfterText>{goal}</AfterText>
          </After>
          <Img src={imgGoal} alt="" />
        </TopInnerSection>
      </TopSection>
      <ChartMain>
        <ChartSection>
          <SectionOne>
            <SectionTitle>{consistency}</SectionTitle>
            <SectionAnswer>{low}</SectionAnswer>
            <LevelSvg>
              <Low />
            </LevelSvg>
          </SectionOne>
          <Horizontal />
          <SectionOne>
            <SectionTitle>{energy}</SectionTitle>
            <SectionAnswer>{low}</SectionAnswer>
            <LevelSvg>
              <Low />
            </LevelSvg>
          </SectionOne>
          <Horizontal />
          <SectionOne>
            <SectionTitle>{motivation}</SectionTitle>
            <SectionAnswer>{medium}</SectionAnswer>
            <LevelSvg>
              <Medium />
            </LevelSvg>
          </SectionOne>
        </ChartSection>
        <VerticalStyled>
          <Vertical />
        </VerticalStyled>
        <ChartSection>
          <SectionOne>
            <SectionTitle>{consistency}</SectionTitle>
            <SectionAnswer>{high}</SectionAnswer>
            <LevelSvg>
              <High />
            </LevelSvg>
          </SectionOne>
          <Horizontal />
          <SectionOne>
            <SectionTitle>{energy}</SectionTitle>
            <SectionAnswer>{high}</SectionAnswer>
            <LevelSvg>
              <High />
            </LevelSvg>
          </SectionOne>
          <Horizontal />
          <SectionOne>
            <SectionTitle>{motivation}</SectionTitle>
            <SectionAnswer>{high}</SectionAnswer>
            <LevelSvg>
              <High />
            </LevelSvg>
          </SectionOne>
        </ChartSection>
      </ChartMain>
      <Box>
        <Chart />
        <BoxText dangerouslySetInnerHTML={{ __html: boxText! }} />
      </Box>
    </>
  );
};

export default ResultsSummary;

const SectionOne = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ChartMain = styled.section`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.5rem;
`;

const ChartSection = styled.section`
  display: flex;
  max-width: 12.125rem;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  justify-content: center;
`;

const SectionTitle = styled(Text)`
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.375rem;
  width: 100%;
  padding-bottom: 0.125rem;
  @media ${tablet} {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25rem;
  }
`;

const LevelSvg = styled.section``;

const SectionAnswer = styled.section`
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
`;

const Img = styled(DynamicImage)`
  width: 100%;
`;

const TopInnerSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
  max-width: 12.15625rem;
`;

const TopSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1.5rem;
  margin: 1.5rem auto 1rem;
  @media ${tablet} {
    gap: 0.5rem;
  }
`;

const Now = styled.section`
  border-radius: 0.5rem;
  border: 1px solid #e0494d;
  background: #fde5e2;
  display: flex;
  height: 2.1875rem;
  padding: 0.375rem 0.625rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media ${tablet} {
    height: 2rem;
  }
`;

const NowText = styled(Text)`
  color: #e0494d;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.375rem;
  @media ${tablet} {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;

const After = styled.section`
  border-radius: 0.5rem;
  border: 1px solid #00b57a;
  background: #e9f6f2;
  display: flex;
  height: 2.1875rem;
  padding: 0.375rem 0.625rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media ${tablet} {
    height: 2rem;
  }
`;

const AfterText = styled.p`
  color: #00b57a;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.375rem;
  @media ${tablet} {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;

const Title = styled(Text)`
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;

  @media ${tablet} {
    font-size: 1.25rem;
    line-height: 1.375rem;
  }
`;

const Subtitle = styled(Text)`
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  padding-top: 0.5rem;
`;

const Box = styled.section`
  border-radius: 0.5rem;
  background: #fdf8e2;
  display: flex;
  padding: 0.75rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  align-self: stretch;
  margin-bottom: 1.5rem;
`;

const BoxText = styled(Text)`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  span {
    font-weight: 700;
  }
`;

const ArrowSvg = styled(Arrow)`
  width: 1.9375rem;
  @media ${mobile} {
    height: 7.75rem;
  }
`;

const Svg = styled.section`
  width: 1.9375rem;
  height: 10.875rem;
  @media ${mobile} {
    height: 7.75rem;
  }
`;

const VerticalStyled = styled.section`
  height: 245px;
`;
